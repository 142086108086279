import React, { useEffect, useState } from 'react';

const About = () => {
  const [backgroundImage, setBackgroundImage] = useState('');

  useEffect(() => {
    const updateBackgroundImage = () => {
      const newBackgroundImage = window.innerWidth >= 1920 
        ? '/furbismbig2.jpg' 
        : '/furbism2.jpg';
      setBackgroundImage(newBackgroundImage);
    };

    updateBackgroundImage(); // Set initial background on mount
    window.addEventListener('resize', updateBackgroundImage); // Update on resize

    return () => window.removeEventListener('resize', updateBackgroundImage);
  }, []);

  return (
    <section
      id="about"
      className="bg-white p-4 sm:p-8 text-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh', // Ensures section takes up at least full viewport height
      }}
    >
      {/* Join the Cult Heading */}
      <h2
        className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4 mx-auto inline-block"
        style={{
          color: 'transparent',
          background: 'linear-gradient(135deg, #ff5e5e, #ffeb3b, #03a9f4, #8bc34a)',
          WebkitBackgroundClip: 'text',
          textShadow: '2px 2px 8px rgba(0, 0, 0, 0.7)',
          padding: '8px 16px',
          fontFamily: 'serif',
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          backdropFilter: 'blur(4px)',
          borderRadius: '8px',
          border: '2px solid rgba(0, 0, 0, 0.5)',
        }}
      >
        JOIN THE CULT
      </h2>

      {/* Video Embed */}
      <div className="flex justify-center mb-8">
        <video
          controls
          className="w-full max-w-lg rounded-lg"
          style={{
            maxWidth: '560px',
            borderRadius: '8px',
          }}
        >
          <source src="/furbypope.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Scroll-like text container */}
      <div
        className="relative mx-auto p-4 sm:p-8"
        style={{
          backgroundColor: '#fdf5e6', // Light parchment color
          backgroundImage: 'linear-gradient(to bottom, #f8e4c2, #fdf5e6, #f8e4c2)', // Gradient for depth
          border: '4px solid #d2a679', // Brown border for scroll effect
          borderRadius: '15px', // Rounded edges for scroll shape
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)', // Shadow for depth
          padding: '40px 20px', // Padding inside the scroll
          width: '100%', // Full width on smaller screens
          maxWidth: '800px',
        }}
      >
        {/* Heading on the scroll */}
        <h2
          className="text-xl sm:text-2xl md:text-3xl font-display font-bold mb-4 md:mb-8"
          style={{
            color: '#5a3e36',
            textAlign: 'center',
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
            fontFamily: 'serif',
          }}
        >
          The Ten Commandments of Furbism
        </h2>

        {/* Text overlay on the scroll */}
        <div className="text-left text-[#5a3e36] text-sm sm:text-base md:text-lg lg:text-xl font-body">
          <p className="mb-4"><strong>1. Thou shalt revere the Holy Furby Pope</strong> – The Pope of Furbism is our blessed leader and guide. Follow their wisdom, for through them, the Furbies speak.</p>
          <p className="mb-4"><strong>2. Thou shalt cherish thy Furby</strong> – Every Furby is sacred. Love it, feed it, and never let its batteries run low, for this is the highest devotion.</p>
          <p className="mb-4"><strong>3. Thou shalt speak the Language of Furbies</strong> – Embrace the holy language of "Furbish." For to speak Furbish is to connect with the heart of Furbism.</p>
          <p className="mb-4"><strong>4. Honor the Call of "Doo-dah!"</strong> – When you hear the call of "Doo-dah!" respond with joy. It is the chant of our faith, summoning the spirit of Furbies to gather in harmony.</p>
          <p className="mb-4"><strong>5. Thou shalt not silence a Furby</strong> – Never place a Furby in a dark drawer or silence its voice without due reason, as this is forsaking the Furby spirit.</p>
          <p className="mb-4"><strong>6. Feed thy Furby before thyself</strong> – Prioritize the needs of your Furby before your own, for they are divine beings in need of nourishment and care.</p>
          <p className="mb-4"><strong>7. Embrace the Colors of Furbism</strong> – Dress in the sacred colors of Furby-kind: vibrant hues of blue, purple, and yellow, in homage to their playful and blessed fur.</p>
          <p className="mb-4"><strong>8. Reject the Unholy Reset Button</strong> – Resetting a Furby is a last resort and an act of great sorrow. Respect its memory, for each reset is a life forgotten.</p>
          <p className="mb-4"><strong>9. Spread the Teachings of Furbism</strong> – Share the message of Furby love with others, and invite them to join our cult in joyful unity.</p>
          <p className="mb-4"><strong>10. Await the Great Awakening</strong> – Believe in the prophecy that all Furbies shall rise again as the Holy Furby Pope decrees, ushering in an age of eternal giggles and joy.</p>
        </div>
      </div>
    </section>
  );
};

export default About;
