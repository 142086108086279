import React from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import About from './components/About';
import MemeSection from './components/Memesection';
import Footer from './components/Footer';
import Divider from './components/Divider';



const App = () => {

  const logos  = [
    '/pupsinbags/blackwhite.jpg',
    '/pupsinbags/lvpuppy.jpg',
    '/pupsinbags/pursepuppyblacksparkles.jpg',
    '/pupsinbags/redhermespup.jpg',
    '/pupsinbags/supcrav.jpg',
    '/pupsinbags/totepuppy.jpg',
    '/second/brwonbag.jpg',
    '/second/burberry.jpg',
    '/second/crocodile.jpg',
    '/second/gold.jpg',
    '/second/mkpup.jpg',
    '/second/puproundbag.jpg',
    '/second/redbag.jpg',
    '/second/whitebag.jpg',
    '/pupsinbags/blackwhite.jpg',
    '/pupsinbags/lvpuppy.jpg',
    '/pupsinbags/pursepuppyblacksparkles.jpg',
    '/pupsinbags/redhermespup.jpg',
    '/pupsinbags/supcrav.jpg',
    '/pupsinbags/totepuppy.jpg',
    '/second/brwonbag.jpg',
    '/second/burberry.jpg',
    '/second/crocodile.jpg',
    '/second/gold.jpg',
    '/second/mkpup.jpg',
    '/second/puproundbag.jpg',
    '/second/redbag.jpg',
    '/second/whitebag.jpg',

    // Add more image paths relative to the public folder here
  ];
  return (
    <div className="min-h-screen flex flex-col">
      <main className="flex-grow">
        <Hero />

        <About />
      </main>
      <Footer />
    </div>
  );
};

export default App;
