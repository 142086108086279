import React, { useState, useEffect } from 'react';

const Hero = () => {
  const [copied, setCopied] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState('');

  const contractAddress = "filler1213231231313431412pump";

  const handleCopy = () => {
    navigator.clipboard.writeText(contractAddress);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  useEffect(() => {
    const updateBackgroundImage = () => {
      const newBackgroundImage = window.innerWidth >= 1920 
        ? '/furbismbig.jpg' 
        : '/furbism1.jpg';
      setBackgroundImage(newBackgroundImage);
    };

    updateBackgroundImage(); // Set initial background on mount
    window.addEventListener('resize', updateBackgroundImage); // Update on resize

    return () => window.removeEventListener('resize', updateBackgroundImage);
  }, []);

  return (
    <section
      id="home"
      className="relative text-gray-800 p-4 sm:p-8 text-center overflow-hidden"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh', // Ensures the section takes full viewport height
      }}
    >
      {/* Navbar */}
      <div
        className="mx-auto p-2 flex justify-between items-center w-full sm:w-3/4 lg:w-2/3 xl:w-1/2"
        style={{
          background: 'linear-gradient(135deg, rgba(59, 194, 225, 0.8), rgba(72, 202, 146, 0.8), rgba(59, 194, 225, 0.8))',
          borderBottomLeftRadius: '20px', // Rounded bottom edges only
          borderBottomRightRadius: '20px',
          boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.8)', // Dark bottom shadow for depth
          borderBottom: '4px solid black', // Black outline on the bottom
        }}
      >
        {/* Logo Image */}
        <img src="/logo.png" alt="Furbism Logo" className="h-10 sm:h-12" />

        <nav className="hidden sm:flex space-x-4 md:space-x-6">
          <a href="#teachings" className="text-white font-bold text-base sm:text-lg" style={{ textShadow: '1px 1px 4px rgba(0, 0, 0, 0.5)' }}>TEACHINGS</a>
          <a href="#language" className="text-white font-bold text-base sm:text-lg" style={{ textShadow: '1px 1px 4px rgba(0, 0, 0, 0.5)' }}>FURBISH LANGUAGE</a>
          <a href="#prophecies" className="text-white font-bold text-base sm:text-lg" style={{ textShadow: '1px 1px 4px rgba(0, 0, 0, 0.5)' }}>PROPHECIES</a>
          <a href="https://pump.fun" target="_blank" rel="noopener noreferrer" className="text-white font-bold text-base sm:text-lg" style={{ textShadow: '1px 1px 4px rgba(0, 0, 0, 0.5)' }}>JOIN US</a>
        </nav>

        <a
          href="https://pump.fun"
          target="_blank"
          rel="noopener noreferrer"
          className="hidden sm:block text-white font-bold text-sm sm:text-lg px-3 sm:px-4 py-1 sm:py-2 rounded-full"
          style={{
            background: 'linear-gradient(145deg, rgba(65, 219, 240, 0.8), rgba(38, 111, 171, 0.8))',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3), inset 0px -4px 8px rgba(0, 0, 0, 0.6)',
            textShadow: '1px 1px 4px rgba(0, 0, 0, 0.5)',
            transition: 'all 0.3s ease',
          }}
          onMouseEnter={(e) => {
            e.target.style.transform = 'scale(1.05)';
            e.target.style.boxShadow = 'none'; // Remove the shadow on hover
          }}
          onMouseLeave={(e) => {
            e.target.style.transform = 'scale(1)';
            e.target.style.boxShadow = '0 4px 10px rgba(0, 0, 0, 0.3), inset 0px -4px 8px rgba(0, 0, 0, 0.6)'; // Restore the shadow on mouse leave
          }}
        >
          RECEIVE BLESSINGS
        </a>
      </div>

      {/* Contract Address Display */}
      <div 
        className="absolute bottom-4 left-1/2 transform -translate-x-1/2 px-4 sm:px-6 py-1 sm:py-2 rounded-lg border-2 sm:border-4 border-black cursor-pointer"
        onClick={handleCopy}
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          backdropFilter: 'blur(10px)',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
          borderRadius: '10px',
          color: 'black',
          fontWeight: 'bold',
          fontSize: '1rem',
          textAlign: 'center',
          transition: 'background-color 0.3s ease',
        }}
      >
        <span style={{ color: 'black' }}>{copied ? 'Copied!' : 'CA: ' + contractAddress}</span>
      </div>
    </section>
  );
};

export default Hero;
