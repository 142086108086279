import React from 'react';

const Footer = () => (
  <footer
    id="contact"
    className="text-white p-4 text-center"
    style={{
      background: 'linear-gradient(135deg, rgba(255, 94, 94, 0.7), rgba(255, 235, 59, 0.7), rgba(3, 169, 244, 0.7), rgba(139, 195, 74, 0.7))', // Colorful gradient
      backdropFilter: 'blur(6px)', // Blur effect for stained glass look
      borderTop: '4px solid rgba(0, 0, 0, 0.5)', // Dark border for defined edges
      borderBottom: '4px solid rgba(0, 0, 0, 0.5)',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)', // Shadow for depth
    }}
  >
    <div className="flex flex-col md:flex-row justify-center items-center space-y-2 md:space-y-0 md:space-x-4">
      <a href="#home" className="hover:underline font-body text-lg" style={{ textShadow: '1px 1px 4px rgba(0, 0, 0, 0.7)' }}>Home</a>
      <a href="#about" className="hover:underline font-body text-lg" style={{ textShadow: '1px 1px 4px rgba(0, 0, 0, 0.7)' }}>About</a>
      <a href="#memes" className="hover:underline font-body text-lg" style={{ textShadow: '1px 1px 4px rgba(0, 0, 0, 0.7)' }}>Memes</a>
      <a href="#contact" className="hover:underline font-body text-lg" style={{ textShadow: '1px 1px 4px rgba(0, 0, 0, 0.7)' }}>Contact</a>
    </div>
    <p className="mt-4 font-body text-sm" style={{ textShadow: '1px 1px 4px rgba(0, 0, 0, 0.7)' }}>&copy; 2024 Furbism. All rights reserved.</p>
  </footer>
);

export default Footer;
